import React from 'react';

import Header from '../../components/Header';
import Footer from '../../components/Footer';

import { Container } from './styles';
import support from '../../utils/support';

const Terms: React.FC = () => {
  return (
    <>
      <Header />

      <Container>
        <h1>Termos de uso</h1>

        <h3>1. Termos</h3>

        <p>
          Ao acessar a aplicação <b>CINCO - Cuidado Inteligente Conectado</b>,
          você concorda em cumprir estes termos de serviço, todas as leis e
          regulamentos aplicáveis e concorda que é responsável pelo cumprimento
          de todas as leis locais aplicáveis. Se você não concordar com algum
          desses termos, não deve usar ou acessar esta aplicação. Os materiais
          contidos neste local são protegidos pelas leis de direitos autorais e
          marcas comerciais aplicáveis.
        </p>

        <h3>2. Uso de Licença</h3>

        <p>
          É concedida permissão para baixar temporariamente uma cópia dos textos
          e imagens no site CINCO, apenas para visualização transitória pessoal
          e não comercial. Esta é a concessão de uma licença, não uma
          transferência de título e, sob esta licença, você não pode:
        </p>

        <ol>
          <li>Modificar ou copiar os materiais;</li>
          <li>
            Usar os materiais para qualquer finalidade comercial ou para
            exibição pública (comercial ou não comercial);
          </li>
          <li>
            Tentar descompilar ou fazer engenharia reversa de qualquer software
            contido na aplicação CINCO;
          </li>
          <li>
            Remover quaisquer direitos autorais ou outras notações de
            propriedade dos materiais;
          </li>
          <li>
            Transferir os materiais para outra pessoa ou &apos;espelhe&apos; os
            materiais em qualquer outro servidor.
          </li>
        </ol>

        <p>
          Esta licença será automaticamente rescindida se você violar alguma
          dessas restrições e poderá ser rescindida pela <b>CINCO</b> a qualquer
          momento. Ao encerrar a visualização desses materiais ou após o término
          desta licença, você deve apagar todos os materiais baixados em sua
          posse, seja em formato eletrónico ou impresso.
        </p>

        <p>
          A CINCO poderá utilizar o seu e-mail para entrar em contato
          posteriormente ou, ainda, enviar materiais que sejam compatíveis com
          os seus endereços. Você poderá solicitar, a qualquer tempo, a exclusão
          do seu e-mail da nossa lista de contatos, enviando uma solicitação
          para o e-mail{' '}
          <a href={`mailto:${support.email}`}>
            <b>{support.email}</b>
          </a>
          .
        </p>

        <h3>3. Isenção de responsabilidade</h3>

        <ol>
          <li>
            Os materiais no site da CINCO são fornecidos &apos;como estão&apos;.
            A CINCO não oferece garantias, expressas ou implícitas, e, por este
            meio, isenta e nega todas as outras garantias, incluindo, sem
            limitação, garantias implícitas ou condições de comercialização,
            adequação a um fim específico ou não violação de propriedade
            intelectual ou outra violação de direitos.
          </li>
          <li>
            Além disso, a CINCO não garante ou faz qualquer representação
            relativa à precisão, aos resultados prováveis ou à confiabilidade do
            uso dos materiais em seu site ou de outra forma relacionado a esses
            materiais ou em sites vinculados a este site.
          </li>
        </ol>

        <h3>4. Limitações</h3>

        <p>
          Em nenhum caso a CINCO ou seus fornecedores serão responsáveis por
          quaisquer danos (incluindo, sem limitação, danos por perda de dados ou
          lucro ou devido a interrupção dos negócios) decorrentes do uso ou da
          incapacidade de usar os materiais na CINCO, mesmo que a CINCO ou um
          representante autorizado dela tenha sido notificado oralmente ou por
          escrito da possibilidade de tais danos. Como algumas jurisdições não
          permitem limitações em garantias implícitas, ou limitações de
          responsabilidade por danos consequentes ou incidentais, essas
          limitações podem não se aplicar a você.
        </p>

        <strong>Precisão dos materiais</strong>

        <p>
          Os materiais exibidos no site da CINCO podem incluir erros técnicos,
          tipográficos ou fotográficos. A CINCO não garante que qualquer
          material em seu site seja preciso, completo ou atual. A CINCO pode
          fazer alterações nos materiais contidos em seu site a qualquer
          momento, sem aviso prévio. No entanto, a CINCO não se compromete a
          atualizar os materiais.
        </p>

        <h3>5. Links</h3>

        <p>
          A CINCO não analisou todos os sites vinculados ao seu site e não é
          responsável pelo conteúdo de nenhum site vinculado. A inclusão de
          qualquer link não implica endosso pela CINCO do site. O uso de
          qualquer site vinculado é por conta e risco do usuário.
        </p>

        <strong>Modificações</strong>

        <p>
          A CINCO pode revisar estes termos de serviço do site a qualquer
          momento, sem aviso prévio. Ao usar este site, você concorda em ficar
          vinculado à versão atual destes termos de serviço.
        </p>

        <strong>Lei aplicável</strong>

        <p>
          Estes termos e condições são regidos e interpretados de acordo com as
          leis da CINCO e você se submete irrevogavelmente à jurisdição
          exclusiva dos tribunais naquele estado ou localidade.
        </p>
      </Container>

      <Footer />
    </>
  );
};

export default Terms;

import React from 'react';
import { VscLoading } from 'react-icons/vsc';

import { ButtonProps } from '../Button';

import { Container } from './styles';

interface ButtonLoadingProps extends ButtonProps {
  isLoading: boolean;
}

const ButtonLoading: React.FC<ButtonLoadingProps> = ({
  isLoading,
  children,
  ...rest
}) => {
  return (
    <Container type="button" disabled={isLoading} {...rest}>
      {isLoading ? <VscLoading size={20} /> : <>{children}</>}
    </Container>
  );
};

export default ButtonLoading;

import styled, { css } from 'styled-components';

export interface ContainerProps {
  btnType?: 'darkBlue' | 'light';
}

const styleVariations = {
  darkBlue: css`
    color: ${props => props.theme.colors.white};
    border: none;
    background: ${props => props.theme.colors.darkBlue};
  `,
  light: css`
    color: ${props => props.theme.colors.primary};
    border: 1px solid ${props => props.theme.colors.primary};
    background: none;
  `,
};

export const Container = styled.button<ContainerProps>`
  display: flex;
  justify-content: center;
  align-items: center;

  height: 55px;
  width: 100%;
  border-radius: 50px;
  font-size: 18px;
  font-weight: 600;

  ${props => styleVariations[props.btnType || 'darkBlue']}

  &:disabled {
    opacity: 0.6;
    cursor: wait;
  }

  @media screen and (max-width: 768px) {
    align-self: center;
  }
`;

import axios from 'axios';
import history from './history';

const baseApiURL = process.env.REACT_APP_API_BASE_URL;

const api = axios.create({
  baseURL: baseApiURL,
});

api.interceptors.request.use(
  async config => {
    const userToken = sessionStorage.getItem('@Cinco:token');
    if (userToken) {
      config.headers.Authorization = `Bearer ${userToken}`;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  },
);

api.interceptors.response.use(
  async response => {
    return response;
  },
  async error => {
    if (error.response && error.response.status === 401) {
      sessionStorage.removeItem('@Cinco:token');
      history.push('/');
    }
    return Promise.reject(error);
  },
);

export { api };

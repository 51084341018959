import React, { useEffect, useRef, useState, useCallback } from 'react';
import { FiAlertCircle } from 'react-icons/fi';
import { useField } from '@unform/core';
import ReactInputMask, { Props as InputMaskProps } from 'react-input-mask';

import { Title, InputContainer, Error } from './styles';

interface InputProps extends InputMaskProps {
  name: string;
  label?: string;
  isRequired?: boolean;
}

const InputMask: React.FC<InputProps> = ({
  name,
  label,
  isRequired,
  ...rest
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const [isFilled] = useState(false);
  const inputRef = useRef<ReactInputMask>(null);
  const { fieldName, defaultValue, error, registerField } = useField(name);

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);
  }, []);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      setValue(ref: any, value: string) {
        ref.setInputValue(value || '');
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      clearValue(ref: any) {
        ref.setAttribute('value', '');
      },
    });
  }, [fieldName, registerField]);

  return (
    <div>
      {label && (
        <Title>
          <span>{label}</span>
          {isRequired && <strong>*</strong>}
        </Title>
      )}
      <InputContainer
        isErrored={!!error}
        isFilled={isFilled}
        isFocused={isFocused}
      >
        <ReactInputMask
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          defaultValue={defaultValue}
          maskPlaceholder={null}
          ref={inputRef}
          {...rest}
        />

        {error && (
          <Error title={error}>
            <FiAlertCircle size={20} />
          </Error>
        )}
      </InputContainer>
    </div>
  );
};

export default InputMask;

import React from 'react';
import { Link } from 'react-router-dom';

import imgError from '../../assets/error.svg';

import Header from '../../components/Header';

import { Container } from './styles';

const Error404: React.FC = () => {
  return (
    <>
      <Header />

      <Container>
        <img src={imgError} alt="error" />

        <h2>Nós não encontramos essa página.</h2>

        <Link to="/">Voltar para o início</Link>
      </Container>
    </>
  );
};

export default Error404;

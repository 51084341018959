import styled from 'styled-components';

export const InputCheckbox = styled.input`
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  cursor: pointer;
  appearance: none;
  background: #fff;
  padding: 9px;
  border-radius: 5px;
  border: 2px solid #7a7786;
  outline: none;
  position: relative;

  display: flex;
  justify-content: center;

  &:checked {
    background-color: ${props => props.theme.colors.primary};

    &:after {
      content: 'Г';
      font-weight: bold;
      position: absolute;
      font-size: 1.2em;
      top: 7px;
      color: #fff;
      line-height: 0;
      transform: rotate(225deg);
    }
  }

  &:disabled {
    opacity: 0.4;
  }
`;

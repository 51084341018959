import { Form } from '@unform/web';
import * as Yup from 'yup';
import { useRef, useState } from 'react';
import { FormHandles } from '@unform/core';
import { PreRegistrationHeader } from '../../components/PreRegistrationHeader';
import support from '../../utils/support';
import {
  Background,
  Container,
  Content,
  Footer,
  FormContainer,
  CheckboxLineForm,
  ButtonLoadingForm,
  HelpContainer,
  ButtonContainer,
} from './styles';
import Input from '../../components/Input';
import InputMask from '../../components/InputMask';
import { colors } from '../../styles/theme/colors';
import Checkbox from '../../components/Checkbox';
import getValidationCPF from '../../utils/getValidationCPF';
import getValidationErrors from '../../utils/getValidationErrors';
import { useToast } from '../../hooks/toast';
import { PreRegistrationCompleted } from './PreRegistrationCompleted';
import { createPreRegistrationService } from '../../services/preRegistration/createPreRegistrationService';
import {
  LinkHomePage,
  LinksHeader,
  SocialMedia,
} from '../../components/PreRegistrationHeader/styles';
import theme from '../../styles/theme';
import Linkedin from '../../assets/linkedin.svg';
import Facebook from '../../assets/facebook.svg';
import Youtube from '../../assets/youtube.svg';
import Intagram from '../../assets/instagram.svg';

interface FormProps {
  name: string;
  birth: string;
  cpf: string;
  phone: string;
  token: string;
  policy: boolean;
}

export const PreRegistration: React.FC = () => {
  const { addToast } = useToast();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isCompleted, setIsCompleted] = useState<boolean>(false);
  const mobile = window.innerWidth <= 768;

  const formRef = useRef<FormHandles>(null);

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  async function onSubmit() {
    const data = formRef.current?.getData() as FormProps;

    try {
      setIsLoading(true);

      const schema = Yup.object().shape({
        name: Yup.string()
          .required('Nome é um campo obrigatório')
          .test({
            name: 'fullName',
            test: value => !!value && value.trim().split(' ').length > 1,
            message: 'O nome completo é obrigatório',
          }),
        birth: Yup.string().required('Data de nascimento obrigatória'),
        cpf: Yup.string()
          .required('O CPF é obrigatório')
          .length(14, 'Digite um CPF válido')
          .test('cpf', 'Digite um CPF válido', value =>
            getValidationCPF(value),
          ),
        phone: Yup.string()
          .length(16, 'Digite um número de telefone válido')
          .required('O telefone é obrigatório'),
        token: Yup.string().required('Senha de acesso obrigatória'),
        policy: Yup.boolean().oneOf(
          [true],
          'O aceite dos termos de uso e da política de privacidade é obrigatório',
        ),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      formRef.current?.setErrors({});

      await createPreRegistrationService(data);
      setIsCompleted(true);
    } catch (error) {
      setIsLoading(false);

      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        formRef.current?.setErrors(errors);
      } else if (error.response) {
        addToast({
          type: 'error',
          title: 'Erro',
          description: `${error.response.data.message}`,
        });
      } else {
        addToast({
          type: 'error',
          title: 'Erro',
          description: 'Entre em contato com o administrador.',
        });
      }
    }
  }

  return (
    <Container>
      <PreRegistrationHeader />
      <div style={{ display: 'flex' }}>
        <Content>
          {isCompleted ? (
            <PreRegistrationCompleted />
          ) : (
            <>
              <FormContainer>
                <h1>Pré-cadastro</h1>
                <p>
                  A sua experiência CINCO já começou. Por favor, informe seus
                  dados para iniciar a sua jornada de cuidado e bem-estar.
                </p>

                <Form ref={formRef} onSubmit={onSubmit}>
                  <Input name="name" label="Nome completo" isRequired />
                  <Input
                    name="birth"
                    label="Data de nascimento"
                    type="date"
                    isRequired
                  />
                  <InputMask
                    mask="999.999.999-99"
                    name="cpf"
                    type="tel"
                    isRequired
                    label="CPF"
                  />
                  <InputMask
                    name="phone"
                    label="Telefone celular"
                    isRequired
                    mask="(99) 9 9999-9999"
                    type="tel"
                  />
                  <Input
                    name="token"
                    label="Código de acesso"
                    type="password"
                    isRequired
                  />

                  <p
                    style={{
                      marginTop: 8,
                      marginBottom: 13,
                      color: colors.grey6,
                    }}
                  >
                    Aviso: O código de acesso não pode ser reutilizado. Por
                    favor, verifique se todas as suas informações estão corretas
                    antes de enviar
                  </p>

                  <CheckboxLineForm>
                    <Checkbox name="policy" />
                    <span>
                      Aceito os <a href="/termos-de-uso">Termos de uso</a> e a{' '}
                      <a href="/politica-de-privacidade">
                        Política de Privacidade
                      </a>
                    </span>
                  </CheckboxLineForm>
                </Form>

                <ButtonContainer>
                  <ButtonLoadingForm
                    isLoading={isLoading}
                    btnType="darkBlue"
                    onClick={onSubmit}
                  >
                    Enviar
                  </ButtonLoadingForm>
                </ButtonContainer>
              </FormContainer>
              <HelpContainer>
                <p>Precisa de ajuda?</p>
                <p>
                  Entre em contato com{' '}
                  <a href={`mailto:${support.email}`}>
                    <b>{support.email}</b>
                  </a>
                </p>
              </HelpContainer>
            </>
          )}
          ;
          {mobile && (
            <Footer>
              <LinksHeader>
                <LinkHomePage
                  href="https://www.saudecinco.com/inicio"
                  color={theme.colors.darkBlue}
                  target="_blank"
                >
                  Site Cinco Saúde
                </LinkHomePage>

                <SocialMedia>
                  <img src={Linkedin} alt="linkedin" />
                  <img src={Facebook} alt="facebook" />
                  <img src={Youtube} alt="youtube" />
                  <img src={Intagram} alt="instagram" />
                </SocialMedia>
              </LinksHeader>
            </Footer>
          )}
        </Content>
        {!mobile && (
          <Background>
            <div />
          </Background>
        )}
      </div>
    </Container>
  );
};

import styled, { keyframes } from 'styled-components';
import { RiAndroidLine, RiAppleLine } from 'react-icons/ri';

const appearFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const AnimatedContainer = styled.div`
  animation: ${appearFromLeft} 1s;
  height: 100%;

  > h1 {
    color: ${props => props.theme.colors.grey};
    margin-bottom: 16px;
    font-weight: 600;
    font-size: 30px;
  }

  > p {
    color: ${props => props.theme.colors.grey2};
    font-size: 15px;
    line-height: 130%;
    margin-bottom: 32px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 382px;

  @media screen and (max-width: 768px) {
    align-items: center;
  }
`;

export const ImageContainer = styled.div`
  display: flex;
  justify-content: center;

  width: 100%;

  img {
    width: 100%;
    max-width: 277px;
    margin-top: 55px;
    margin-bottom: 30px;
  }

  svg {
    margin-right: 10px;
  }
`;

export const MessageCompleted = styled.p`
  margin-top: 35px;
  margin-bottom: 32px;
  font-weight: 500;
  font-size: 17px;
  font-style: normal;
  color: ${props => props.theme.colors.grey1};
`;

export const LogoAppContainer = styled.div`
  display: flex;
  gap: 48px;
  margin-bottom: 295px;
  max-width: 320px;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    margin-bottom: 50px;
  }
`;

export const LogoAppContent = styled.div`
  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: center;
`;

export const AndroidIcon = styled(RiAndroidLine)`
  width: 16px;
  height: 16px;
  color: ${props => props.theme.colors.grey1};
`;

export const AppleIcon = styled(RiAppleLine)`
  width: 16px;
  height: 16px;
  color: ${props => props.theme.colors.grey1};
`;

export const SystemContent = styled.div`
  display: flex;
  min-height: 0;
  gap: 2px;
  align-items: center;
`;

export const SystemName = styled.p`
  color: ${props => props.theme.colors.grey1};
  font-size: 10px;
  font-weight: 500;
`;

export const DownloadContent = styled.div``;

export const LinkName = styled.a`
  color: ${props => props.theme.colors.darkBlue4};
  font-size: 18px;
  font-weight: 700;
  line-height: 32px;
  text-decoration: none;
`;

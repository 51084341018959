import styled from 'styled-components';

export const Container = styled.footer`
  display: flex;
  align-items: center;

  height: 150px;
  width: 100%;
  background: ${props => props.theme.colors.darkBlue2};

  div {
    display: flex;
    justify-content: space-between;
    align-items: center;

    width: 100%;
    max-width: 1300px;
    margin: 0 auto;
    padding: 0 15px;
  }
`;

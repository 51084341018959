import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  height: calc(100vh - 100px);
  width: 100%;

  h2 {
    font-weight: bold;
    font-size: 30px;
    margin-top: 30px;
    color: ${props => props.theme.colors.grey};
  }

  a {
    margin-top: 30px;
    padding: 15px 35px;
    border-radius: 100px;
    background: ${props => props.theme.colors.darkBlue};
    color: #fff;
    font-weight: bold;
    font-size: 20px;
    text-decoration: none;
  }
`;

import styled from 'styled-components';

export const Container = styled.header`
  display: flex;
  align-items: center;

  height: 100px;
  width: 100%;
  border-bottom: 2px solid ${props => props.theme.colors.grey5};

  div {
    display: flex;
    align-items: center;

    width: 100%;
    max-width: 1300px;
    margin: 0 auto;
    padding: 0 15px;
  }
`;

import { CreatePreRegistrationServiceRequestDTO } from '../../dtos/CreatePreRegistrationServiceRequestDTO';
import { api } from '../api';

export const createPreRegistrationService = async ({
  name,
  birth,
  cpf,
  phone,
  token,
}: CreatePreRegistrationServiceRequestDTO): Promise<void> => {
  const { data } = await api.post('/patients/preRegistration', {
    name,
    birth,
    cpf,
    phone,
    token,
  });

  return data;
};

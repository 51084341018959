import {
  AndroidIcon,
  AnimatedContainer,
  AppleIcon,
  Content,
  DownloadContent,
  ImageContainer,
  LinkName,
  LogoAppContainer,
  LogoAppContent,
  MessageCompleted,
  SystemContent,
  SystemName,
} from './styles';
import ConfirmationImage from '../../../assets/confirmation-illustra.svg';
import LogoApp from '../../../assets/logoApp.svg';
import { LinksAppEnum } from '../../../enums/LinksAppEnum';

export const PreRegistrationCompleted: React.FC = () => {
  return (
    <AnimatedContainer>
      <h1>Pré-cadastro realizado!</h1>

      <Content>
        <ImageContainer>
          <img src={ConfirmationImage} alt="" />
        </ImageContainer>

        <MessageCompleted>
          Deu tudo certo! Agora você já pode fazer o download do nosso
          aplicativo e continuar a sua experiência única de cuidado e bem-estar.
        </MessageCompleted>

        <LogoAppContainer>
          <LogoAppContent>
            <img src={LogoApp} alt="" />
            <DownloadContent>
              <LinkName href={LinksAppEnum.PLAY_STORE}>Download</LinkName>
              <SystemContent>
                <AndroidIcon />
                <SystemName>Android</SystemName>
              </SystemContent>
            </DownloadContent>
          </LogoAppContent>

          <LogoAppContent>
            <img src={LogoApp} alt="" />
            <DownloadContent>
              <LinkName href={LinksAppEnum.APPLE_STORE}>Download</LinkName>
              <SystemContent>
                <AppleIcon />
                <SystemName>Apple</SystemName>
              </SystemContent>
            </DownloadContent>
          </LogoAppContent>
        </LogoAppContainer>
      </Content>
    </AnimatedContainer>
  );
};

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  max-width: 1100px;
  margin: 30px auto 50px;
  padding: 0 15px;
  color: ${props => props.theme.colors.grey};

  h1 {
    margin: 0 auto 30px;
    font-size: 45px;
    font-weight: bold;
  }

  h3 {
    font-size: 30px;
    font-weight: bold;
    margin: 20px 0 20px;
  }

  p {
    font-size: 18px;
    margin-bottom: 20px;
    line-height: 150%;

    a {
      text-decoration: none;

      b {
        color: ${props => props.theme.colors.primary};
      }
    }
  }

  ol {
    list-style-position: inside;
    margin-left: 20px;
    line-height: 150%;
    margin-bottom: 20px;

    li {
      font-size: 18px;
    }
  }

  strong {
    font-size: 20px;
    margin-bottom: 20px;
    font-weight: bold;
  }
`;

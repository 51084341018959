import React from 'react';

import Header from '../../components/Header';
import Footer from '../../components/Footer';

import { Container, TableContainer } from './styles';
import support from '../../utils/support';

const Policy: React.FC = () => {
  return (
    <>
      <Header />

      <Container>
        <h1>Política de privacidade</h1>

        <span>Última atualização: 14/05/2021</span>

        <p>
          A sua privacidade é um assunto sério para nós. E a política da
          plataforma <b>CINCO - Cuidado Inteligente Conectado</b> respeita a sua
          privacidade em relação a qualquer informação sua, que possamos coletar
          no site, e aplicações <b>CINCO</b>, bem como nos outros sites que
          possuímos e operamos.
        </p>

        <p>
          Solicitamos informações pessoais apenas quando realmente precisamos
          delas para lhe fornecer um serviço melhor. Fazemos isso por meios
          justos e legais, com o seu conhecimento e consentimento. Também
          informamos por que estamos coletando e como será usado. Apenas retemos
          as informações coletadas pelo tempo necessário para fornecer o serviço
          solicitado. Quando armazenamos dados, os protegemos dentro de meios
          comercialmente aceitáveis para evitar perdas e roubos, bem como
          acesso, divulgação, cópia, uso ou modificação não autorizados.
        </p>

        <p>
          Todos os dados informados, uma vez registrados, são armazenados e de
          responsabilidade da plataforma <b>CINCO</b>. Já no que concerne a
          execução do atendimento, assim como as informações transmitidas pelos
          profissionais que realizarão o serviço são de responsabilidade e
          administração do <b>CINCO</b>.
        </p>

        <p>
          Não compartilhamos informações de identificação pessoal publicamente
          ou com terceiros, exceto quando exigido por lei.
        </p>

        <h3>Dados pessoais coletados e finalidade:</h3>

        <TableContainer>
          <table>
            <thead>
              <tr>
                <td>Categoria</td>
                <td>Descrição</td>
                <td>Finalidade</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Informações pessoais</td>
                <td>Nome completo</td>
                <td>
                  Além de oferecer uma informação mais personalizada, coletamos
                  o nome completo do usuário para garantir que se trata do mesmo
                  CPF e evitar fraudes.
                </td>
              </tr>

              <tr>
                <td>Informações de contato</td>
                <td>E-mail</td>
                <td>
                  Viabilizar a comunicação com a equipe <b>CINCO</b> e enviar
                  informativos que possam ser úteis ao usuário.
                </td>
              </tr>

              <tr>
                <td>Informações de contato</td>
                <td>Telefone celular</td>
                <td>
                  Viabilizar a comunicação com a equipe <b>CINCO</b>.
                </td>
              </tr>

              <tr>
                <td>Informações pessoais</td>
                <td>CPF</td>
                <td>Validar o CPF e evitar fraudes.</td>
              </tr>

              <tr>
                <td>Informações pessoais</td>
                <td>Data de nascimento</td>
                <td>
                  Validar o CPF, evitar fraudes e necessário para os
                  profissionais de saúde que realizarão atendimentos.
                </td>
              </tr>

              <tr>
                <td>Informações pessoais</td>
                <td>Endereço</td>
                <td>
                  Necessário na contratação do plano para o envio da pulseira
                  inteligente de monitoramento (dispositivo vestível de saúde).
                </td>
              </tr>

              <tr>
                <td>Informações de saúde</td>
                <td>Hábitos e comportamentos</td>
                <td>
                  Informações que serão coletadas durante os atendimentos
                  remotos, por ligação ou vídeo, que serão armazenadas em seus
                  prontuários e serão utilizadas para realizar uma avaliação
                  mais precisa sobre o estado de saúde de cada paciente.
                </td>
              </tr>

              <tr>
                <td>Informações de saúde</td>
                <td>Doenças pré-existentes e/ou diagnósticos anteriores</td>
                <td>
                  Informações que serão coletadas durante os atendimentos
                  remotos, por ligação ou vídeo, que serão armazenadas em seus
                  prontuários e serão utilizadas para realizar uma avaliação
                  mais precisa sobre o estado de saúde de cada paciente.
                </td>
              </tr>

              <tr>
                <td>Informações de saúde</td>
                <td>Detalhes necessários para anamnese de saúde</td>
                <td>
                  Informações que serão coletadas durante os atendimentos
                  remotos, por ligação ou vídeo, que serão armazenadas em seus
                  prontuários e serão utilizadas para realizar uma avaliação
                  mais precisa sobre o estado de saúde de cada paciente.
                </td>
              </tr>

              <tr>
                <td>Informações de saúde</td>
                <td>
                  Pressão arterial, oxigenação, batimentos cardíacos e
                  temperatura, podendo ainda coletar outras informações
                  importantes sobre a minha saúde, como qualidade do sono e
                  quantidade de passos
                </td>
                <td>
                  Dados do paciente coletados remotamente através de dispositivo
                  vestível de saúde, utilizado para auxiliar os profissionais de
                  saúde a orientar melhor o paciente, com base em seu histórico
                  de saúde.
                </td>
              </tr>

              <tr>
                <td>Informações pessoais</td>
                <td>Dados de localização</td>
                <td>
                  Este aplicativo precisa do GPS ativo em segundo plano para
                  coletar dados de localização, a fim de ativar a funcionalidade
                  de pedômetro (contador de passos e calorias). Essas
                  informações são necessárias para fornecer uma experiência mais
                  completa de saúde, baseada na quantidade de movimentos
                  diários.
                </td>
              </tr>
            </tbody>
          </table>
        </TableContainer>

        <p>
          Ao informar os dados no nosso site, você se responsabiliza pela
          veracidade deles, bem como poderá responder legalmente pela inclusão
          dos dados.
        </p>

        <p>
          A nossa plataforma pode ter links para sites externos que não são
          operados por nós. Esteja ciente de que não temos controle sobre o
          conteúdo e práticas desses sites e não podemos aceitar
          responsabilidade por suas respectivas políticas de privacidade. Você é
          livre para recusar a nossa solicitação de informações pessoais,
          entendendo que, talvez, não possamos fornecer alguns dos serviços
          desejados.
        </p>

        <p>
          O uso continuado de nosso site será considerado como aceitação de
          nossas práticas em torno de privacidade e informações pessoais. Se
          você tiver alguma dúvida sobre como lidamos com dados do usuário e
          informações pessoais, entre em contato conosco pelo e-mail{' '}
          <a href={`mailto:${support.email}`}>
            <b>{support.email}</b>
          </a>
        </p>

        <p>
          Os dados registrados podem ficar salvos na nossa base por um período
          de 10 anos. Também estamos aptos a excluir os dados a qualquer tempo
          antes desse período, por interesse da própria plataforma. Você também
          poderá solicitar a remoção dos seus dados da nossa base a qualquer
          tempo, através do e-mail{' '}
          <a href={`mailto:${support.email}`}>
            <b>{support.email}</b>
          </a>
        </p>

        <h3>Política de Cookies</h3>

        <strong>O que são cookies?</strong>

        <p>
          Como é prática comum em quase todos os sites profissionais, este site
          pode usar cookies em algumas ocasiões, que são pequenos arquivos
          baixados no seu computador, para melhorar sua experiência. Esta página
          descreve quais informações eles coletam, como as usamos e por que às
          vezes precisamos armazenar esses cookies. Também compartilharemos como
          você pode impedir que esses cookies sejam armazenados, no entanto,
          isso pode fazer o downgrade ou &apos;quebrar&apos; certos elementos da
          funcionalidade do site.
        </p>

        <strong>Como usamos os cookies?</strong>

        <p>
          Podemos utilizar cookies por vários motivos, detalhados abaixo.
          Infelizmente, na maioria dos casos, não existem opções padrão do setor
          para desativar os cookies sem desativar completamente a funcionalidade
          e os recursos que eles adicionam a este site.
        </p>

        <p>
          É recomendável que você deixe todos os cookies, se não tiver certeza
          se precisa ou não deles, caso sejam usados para fornecer um serviço
          que você usa.
        </p>

        <strong>Desativar cookies</strong>

        <p>
          Você pode impedir a configuração de cookies ajustando as configurações
          do seu navegador (consulte a Ajuda do navegador para saber como fazer
          isso). Esteja ciente de que a desativação de cookies afetará a
          funcionalidade deste e de muitos outros sites que você visita. A
          desativação de cookies geralmente resultará na desativação de
          determinadas funcionalidades e recursos deste site. Portanto, é
          recomendável que você não desative os cookies.
        </p>

        <strong>Cookies relacionados à conta</strong>

        <p>
          Se você criar uma conta conosco, poderemos usar cookies para o
          gerenciamento do processo de inscrição e administração geral. Esses
          cookies geralmente serão excluídos quando você sair do sistema, porém,
          em alguns casos, eles poderão permanecer posteriormente para lembrar
          as preferências do seu site ao sair. Não se preocupe, senhas e
          informações bancárias ou estritamente pessoais não são armazenados em
          cookies.
        </p>

        <strong>Cookies relacionados ao login</strong>

        <p>
          Podemos utilizar cookies quando você está logado, para que possamos
          lembrar dessa ação. Isso evita que você precise fazer login sempre que
          visitar uma nova página. Esses cookies são normalmente removidos ou
          limpos quando você efetua logout para garantir que você possa acessar
          apenas a recursos e áreas restritas ao efetuar login.
        </p>

        <strong>Cookies relacionados a boletins por e-mail</strong>

        <p>
          Este site pode oferecer serviços de assinatura de boletim informativo
          ou e-mail e os cookies podem ser usados para lembrar se você já está
          registrado e se deve mostrar determinadas notificações válidas apenas
          para usuários inscritos ou não inscritos.
        </p>

        <strong>Pedidos processando cookies relacionados</strong>

        <p>
          Este site oferece facilidades de comércio eletrônico ou pagamento e
          alguns cookies são essenciais para garantir que seu pedido seja
          lembrado entre as páginas, para que possamos processá-lo
          adequadamente.
        </p>

        <strong>Cookies relacionados às pesquisas</strong>

        <p>
          Periodicamente, podemos oferecer pesquisas e questionários para
          fornecer informações interessantes, ferramentas úteis ou para entender
          nossa base de usuários com mais precisão. Essas pesquisas podem usar
          cookies para lembrar quem já participou numa pesquisa ou para fornecer
          resultados precisos após a alteração das páginas.
        </p>

        <strong>Cookies relacionados a formulários</strong>

        <p>
          Quando você envia dados por meio de um formulário como os encontrados
          nas páginas de contacto ou nos formulários de comentários, os cookies
          podem ser configurados para lembrar os detalhes do usuário para
          correspondência futura.
        </p>

        <strong>Cookies de preferências do site</strong>

        <p>
          Para proporcionar uma ótima experiência neste site, fornecemos a
          funcionalidade para definir suas preferências de como esse site é
          executado quando você o usa. Para lembrar suas preferências,
          precisamos definir cookies para que essas informações possam ser
          chamadas sempre que você interagir com uma página for afetada por suas
          preferências.
        </p>

        <strong>Cookies de Terceiros</strong>

        <p>
          Em alguns casos especiais, também usamos cookies fornecidos por
          terceiros confiáveis. A seção a seguir detalha quais cookies de
          terceiros você pode encontrar através deste site. Este site usa o
          Google Analytics, que é uma das soluções de análise mais difundidas e
          confiáveis da Web, para nos ajudar a entender como você usa o site e
          como podemos melhorar sua experiência. Esses cookies podem rastrear
          itens como quanto tempo você gasta no site e as páginas visitadas,
          para que possamos continuar produzindo conteúdo atraente. Para mais
          informações sobre os cookies do Google Analytics, consulte a página
          oficial do{' '}
          <a
            href="https://support.google.com/analytics/answer/7318509?hl=pt-BR"
            target="_blank"
            rel="noreferrer"
          >
            Google Analytics
          </a>
          .
        </p>

        <p>
          As análises de terceiros são usadas para rastrear e medir o uso deste
          site, para que possamos continuar produzindo conteúdo atrativo. Esses
          cookies podem rastrear itens como o tempo que você passa no site ou as
          páginas visitadas, o que nos ajuda a entender como podemos melhorar o
          site para você.
        </p>

        <p>
          Periodicamente, testamos novos recursos e fazemos alterações sutis na
          maneira como o site se apresenta. Quando ainda estamos testando novos
          recursos, esses cookies podem ser usados para garantir que você receba
          uma experiência consistente enquanto estiver no site, enquanto
          entendemos quais otimizações os nossos usuários mais apreciam.
        </p>

        <p>
          À medida que oferecemos produtos, é importante entendermos as
          estatísticas sobre quantos visitantes de nosso site realmente compram
          e, portanto, esse é o tipo de dados que esses cookies poderão
          rastrear. Isso é importante para você, pois significa que podemos
          fazer previsões de negócios com precisão que nos permitem analisar
          nossos custos de publicidade e produtos para garantir o melhor preço
          possível.
        </p>

        <strong>Mais informações</strong>

        <p>
          Esperamos que esteja esclarecido e, como mencionado anteriormente, se
          houver algo que você não tem certeza se precisa ou não, geralmente é
          mais seguro deixar os cookies ativados, caso interaja com um dos
          recursos que você usa em nosso site.
        </p>
      </Container>

      <Footer />
    </>
  );
};

export default Policy;

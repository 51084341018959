import styled from 'styled-components';
import preRegisterBackground from '../../assets/retratos-de-adolescentes-felizes-isolados.png';
import ButtonLoading from '../../components/ButtonLoading';
import Button from '../../components/Button';

export const Container = styled.div`
  align-items: stretch;
  min-height: 100vh;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  max-width: 720px;
  margin-bottom: 50px;

  > div {
    width: 100%;
    max-width: 450px;
    margin: 50px auto 0;
    padding: 0 20px;
  }
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    font-weight: 500;
    font-size: 15px;
    color: ${props => props.theme.colors.grey1};

    a {
      text-decoration: none;

      b {
        color: ${props => props.theme.colors.darkBlue2};
      }
    }
  }
`;

export const Background = styled.div`
  flex: 1;
  background: url(${preRegisterBackground}) no-repeat center;
  background-size: cover;

  div {
    width: 100%;
    height: 100%;

    background: linear-gradient(316.13deg, #2d9cdb -2.05%, #305be5 53.02%);
    opacity: 0.8;
  }
`;

export const FormContainer = styled.div`
  height: 100%;

  > h1 {
    color: ${props => props.theme.colors.grey};
    margin-bottom: 16px;
    font-weight: 600;
    font-size: 30px;
  }

  > p {
    color: ${props => props.theme.colors.grey2};
    font-size: 15px;
    line-height: 130%;
    margin-bottom: 35px;
    font-weight: 500;
  }

  form {
    > div {
      & + div {
        margin-top: 35px;
      }
    }
  }
`;

export const CheckboxLineForm = styled.div`
  display: flex;
  align-items: center;

  span {
    margin-left: 10px;
    font-size: 15px;

    a {
      font-weight: 600;
      color: ${props => props.theme.colors.tertiary};
      text-decoration: none;
      margin: 0 4px;
    }
  }
`;

export const ButtonLoadingForm = styled(ButtonLoading)`
  margin-top: 50px;
  max-width: 200px;
`;

export const ButtonForm = styled(Button)`
  margin-top: 50px;
  max-width: 200px;
`;

export const HelpContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    font-weight: 500;
    font-size: 15px;
    color: ${props => props.theme.colors.grey1};

    a {
      text-decoration: none;

      b {
        color: ${props => props.theme.colors.darkBlue2};
      }
    }
  }
`;

export const ButtonContainer = styled.div`
  @media screen and (max-width: 768px) {
    width: 100%;
    display: flex;
    justify-content: center;
  }
`;

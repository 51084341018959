import styled from 'styled-components';
import { colors } from '../../styles/theme/colors';

interface Props {
  mobile?: boolean;
}

export const Container = styled.div<Props>`
  display: flex;
  align-items: center;
  width: 100%;
  height: 118px;
  padding: 0px 80px 0px 80px;
  justify-content: ${({ mobile }) => (mobile ? 'center' : 'space-between')};
  background-color: white;
`;

export const LinkHomePage = styled.a`
  font-family: Raleway;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  text-decoration: none;
  :visited {
    color: ${colors.darkBlue};
  }
`;

export const LinksHeader = styled.div`
  display: flex;
  gap: 4.5rem;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    gap: 1rem;
    align-items: center;
  }
`;

export const SocialMedia = styled.div`
  display: flex;
  gap: 2rem;
  align-items: center;
  justify-content: space-between;
`;

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  max-width: 1100px;
  margin: 30px auto 50px;
  padding: 0 15px;

  h1 {
    margin: 0 auto 30px;
    font-size: 45px;
    font-weight: bold;
  }

  h3 {
    font-size: 30px;
    font-weight: bold;
    margin: 20px 0 20px;
  }

  p {
    font-size: 18px;
    margin-bottom: 20px;
    line-height: 150%;

    b {
      color: ${props => props.theme.colors.primary};
    }

    a {
      font-weight: bold;
      text-decoration: none;
      color: ${props => props.theme.colors.primary};
    }
  }

  ol {
    list-style-position: inside;
    margin-left: 20px;
    line-height: 150%;
    margin-bottom: 20px;

    li {
      font-size: 18px;
    }
  }

  strong {
    font-size: 20px;
    margin-bottom: 20px;
    font-weight: bold;
  }

  span {
    font-size: 18px;
    margin: 20px 0 20px;
  }
`;

export const TableContainer = styled.div`
  width: 100%;
  margin-bottom: 30px;
  overflow-x: auto;

  &::-webkit-scrollbar {
    height: 10px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background: ${props => props.theme.colors.grey3};
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: ${props => props.theme.colors.tertiary};
  }

  table {
    font-size: 18px;
    border-collapse: collapse;
    margin-bottom: 10px;
    min-width: 600px;

    thead {
      font-weight: bold;
    }

    td {
      padding: 10px 10px;
      border: 1px solid ${props => props.theme.colors.grey5};

      b {
        color: ${props => props.theme.colors.primary};
      }
    }
  }
`;

import React from 'react';
import { Switch, Route } from 'react-router-dom';

// import Home from '../pages/Home';
import Register from '../pages/Register';
import Terms from '../pages/Terms';
import Policy from '../pages/Policy';
import Error404 from '../pages/Error404';
import { PreRegistration } from '../pages/PreRegistration';

const Routes: React.FC = () => (
  <Switch>
    <Route path="/" exact component={PreRegistration} />
    <Route path="/termos-de-uso" exact component={Terms} />
    <Route path="/politica-de-privacidade" exact component={Policy} />
    <Route path="*" component={Error404} />
  </Switch>
);

export default Routes;

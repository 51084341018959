export const colors = {
  white: '#FFFFFF',
  lightBlue2: '#dbe7f2',
  lightBlue: '#A0DEF2',
  secondary: '#30E3E5',
  primary: '#2D9CDB',
  darkBlue: '#305BE5',
  darkBlue3: '#004268',
  darkBlue2: '#165B83',
  darkBlue4: '#1B70A0',
  tertiary: '#1C71A1',
  black: '#000000',
  grey: '#33303E',
  grey1: '#5F5C6B',
  grey2: '#7A7786',
  grey5: '#D6D5DC',
  grey3: '#E0E0E0',
  grey4: '#F1F0F5',
  grey6: '#8C8A97',
  danger: '#C53030',
  red: '#EB5757',
  lightDanger: '#fdbdd1',
  green: '#00d36e',
};

import React from 'react';
import { Link } from 'react-router-dom';

import logoCinco from '../../assets/logo-cinco-white.svg';
import logoHu from '../../assets/logo-hu-white.svg';

import { Container } from './styles';

const Footer: React.FC = () => {
  return (
    <Container>
      <div>
        <Link to="/">
          <img src={logoCinco} alt="logo cinco" />
        </Link>
        <a href="https://www.inovahu.com" target="_blank" rel="noreferrer">
          <img src={logoHu} alt="logo hu" />
        </a>
      </div>
    </Container>
  );
};

export default Footer;

import React, { createContext, useContext, useState, useCallback } from 'react';
import axios from 'axios';
import { Subscriber } from '../entities/Subscriber';
import { CreateOrUpdateSubscriberDTO } from '../dtos/CreateOrUpdateSubscriberDTO';

interface UserContextData {
  user?: Subscriber;
  getUserByCpf(cpf: string): Promise<void>;
  createOrUpdateSubscriber(data: CreateOrUpdateSubscriberDTO): Promise<void>;
  saveLocal(userData: Partial<Subscriber>): void;
  cleanLocal(): void;
}

const baseApiURL = process.env.REACT_APP_API_BASE_URL;
const UserContext = createContext<UserContextData>({} as UserContextData);

export const UserProvider: React.FC = ({ children }) => {
  const [user, setUser] = useState<Subscriber>({} as Subscriber);

  const getUserByCpf = async (cpf: string): Promise<void> => {
    const { data } = await axios.get<Subscriber>(`${baseApiURL}/subscribers`, {
      params: {
        cpf,
      },
    });

    if (!data) {
      setUser({ ...user, cpf });
    } else {
      setUser({ ...data });
    }
  };

  // TODO try/catchs

  const createOrUpdateSubscriber = async ({
    name,
    cpf,
    email,
    phone,
    whatsapp,
  }: CreateOrUpdateSubscriberDTO): Promise<void> => {
    const { data } = await axios.post<Subscriber>(`${baseApiURL}/subscribers`, {
      name,
      cpf,
      email,
      phone,
      whatsapp,
    });

    setUser({ ...data });
  };

  const saveLocal = useCallback(
    (userData: Partial<Subscriber>) => {
      setUser({ ...user, ...userData });
    },
    [user],
  );

  const cleanLocal = useCallback(() => {
    setUser({} as Subscriber);
  }, []);

  return (
    <UserContext.Provider
      value={{
        user,
        createOrUpdateSubscriber,
        getUserByCpf,
        saveLocal,
        cleanLocal,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUser = (): UserContextData => {
  const context = useContext(UserContext);

  if (!context) {
    throw new Error('useUser must be used within a UserProvider');
  }

  return context;
};

import styled, { css } from 'styled-components';

import Tooltip from '../Tooltip';

interface InputContainerProps {
  isFocused: boolean;
  isFilled: boolean;
  isErrored: boolean;
}

export const Title = styled.div`
  display: flex;

  font-weight: 600;
  margin-bottom: 5px;

  span {
    color: ${props => props.theme.colors.grey1};
    font-size: 16px;
  }

  strong {
    color: ${props => props.theme.colors.red};
    font-size: 20px;
    margin-left: 3px;
  }
`;

export const InputContainer = styled.div<InputContainerProps>`
  display: flex;
  align-items: center;

  background: #fff;
  border-radius: 4px;
  padding: 15px 19px;
  max-width: 768px;
  width: 100%;

  border: 1px solid ${props => props.theme.colors.grey3};
  color: red;

  & + div {
    margin-top: 8px;
  }

  ${props =>
    props.isErrored &&
    css`
      border-color: ${props.theme.colors.danger};
    `}

  /* ${props =>
    props.isFilled &&
    css`
      border-color: ${props.theme.colors.grey2};
    `} */

  ${props =>
    props.isFocused &&
    css`
      border-color: ${props.theme.colors.grey2};
    `}

  input {
    width: 100%;
    background: transparent;
    border: 0;
    color: ${props => props.theme.colors.grey};
    font-size: 16px;
    font-weight: 600;

    &:focus {
      outline: none;
      box-shadow: 0 0 0 0;
      border: 0 none;
    }

    &::placeholder {
      color: red;
    }

    &:disabled {
      opacity: 0.4;
    }
  }

  svg {
    margin-right: 16px;
  }
`;

export const Error = styled(Tooltip)`
  margin-left: 16px;
  height: 20px;

  svg {
    margin: 0;
    color: red;
  }

  span {
    background: ${props => props.theme.colors.danger};
    color: #fff;

    &::before {
      border-color: ${props => props.theme.colors.danger} transparent;
    }
  }
`;

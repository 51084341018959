import { Container, LinksHeader, SocialMedia, LinkHomePage } from './styles';
import Logo from '../../assets/logo-cinco-blue.svg';
import Linkedin from '../../assets/linkedin.svg';
import Facebook from '../../assets/facebook.svg';
import Youtube from '../../assets/youtube.svg';
import Intagram from '../../assets/instagram.svg';
import theme from '../../styles/theme';

export const PreRegistrationHeader: React.FC = () => {
  const mobile = window.innerWidth <= 768;
  return (
    <Container mobile={mobile}>
      <div>
        <img src={Logo} alt="Logo Cinco" />
      </div>

      {!mobile && (
        <LinksHeader>
          <LinkHomePage
            href="https://www.saudecinco.com/inicio"
            color={theme.colors.darkBlue}
            target="_blank"
          >
            Site Cinco Saúde
          </LinkHomePage>

          <SocialMedia>
            <img src={Linkedin} alt="linkedin" />
            <img src={Facebook} alt="facebook" />
            <img src={Youtube} alt="youtube" />
            <img src={Intagram} alt="instagram" />
          </SocialMedia>
        </LinksHeader>
      )}
    </Container>
  );
};

import styled, { keyframes } from 'styled-components';
import Button from '../Button';

const animateLoading = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const Container = styled(Button)`
  svg {
    animation: ${animateLoading} 0.6s linear infinite;
  }
`;
